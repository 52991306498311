<template>
  <div>
    <el-table
      :data="orderData"
      style="width: 100%; border: 1px solid #ebeef5"
      height="750px"
      border
      :row-class-name="tableRowClassName"
      @sort-change="sortChange"
    >
      <el-table-column prop="starnum" label="服务星级">
        <template slot-scope="scope">{{
          scope.row.starnum ? `${scope.row.starnum}星` : '--'
        }}</template>
      </el-table-column>

      <el-table-column prop="contactPersonName" label="预约人" width="140" show-overflow-tooltip>
        <template slot-scope="scope">
          <span
            class="contact-level"
            :style="{
              opacity: scope.row.customerLevelName ? '1' : '0',
              background:
                (['A', 'B'].includes(scope.row.customerLevelName) && '#ff810f') || '#2861E2',
            }"
            >{{ scope.row.customerLevelName }}</span
          >
          <span class="contact-link" @click.stop="gotoCustomerDetail(scope.row)">{{
            scope.row.contactPersonName
          }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="babySexName" label="宝宝信息" width="100">
        <template slot-scope="scope">
          {{ scope.row.babySexName ? `${scope.row.babySexName}·${scope.row.babyAge}` : '--' }}
        </template>
      </el-table-column>

      <el-table-column
        prop="appointmentShootDatetime"
        label="预约时间"
        width="130"
        sortable="custom"
      >
        <template slot-scope="scope">
          <span>{{
            (scope.row.appointmentShootDate || '') + ' ' + (scope.row.appointmentShootTime || '') ||
            '--'
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="contactPhoneNumber" label="预约电话" width="100">
        <template slot-scope="scope">
          {{ scope.row.contactPhoneNumber.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2') }}
        </template>
      </el-table-column>

      <el-table-column prop="orderProduct" label="产品" width="160">
        <template slot-scope="scope">
          <el-popover placement="bottom" trigger="hover" :open-delay="500">
            <div>{{ (scope.row.orderProduct && scope.row.orderProduct.productName) || '' }}</div>
            <div
              v-if="scope.row.erpProductList && scope.row.erpProductList.length"
              class="popover__title"
            >
              ERP产品：
            </div>
            <template v-if="scope.row.erpProductList">
              <div
                v-for="(item, index) in scope.row.erpProductList"
                :key="index"
                class="popover__item"
              >
                {{ item.prdtname }} {{ item.prdtcount }}{{ item.unit }}
              </div>
            </template>

            <span slot="reference">{{
              (scope.row.orderProduct && scope.row.orderProduct.productName) || '--'
            }}</span>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="serviceItems" label="服务人员" width="280">
        <template slot-scope="scope">
          <div v-if="scope.row.serviceItems && scope.row.serviceItems.length" class="service-box">
            <div v-for="(item, index) in scope.row.serviceItems" :key="index" class="service-item">
              <div class="service-item__title">
                {{ item.baseServiceTypeName + '师' }}
              </div>
              <div
                class="service-item__name"
                :style="{ color: item.serviceUserName ? '#333333' : '#F1711B' }"
              >
                {{ scope.row.status === 1 ? '--' : item.serviceUserName || '待安排' }}
              </div>
            </div>
          </div>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column prop="orderProduct" label="流程单备注" min-width="160" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.erpOrderRemark || '--' }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="isSubmit" label="新品推荐" width="70" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.isSubmit"
            class="num-btn"
            type="text"
            @click.stop="gotoBeforeShoot(scope.row)"
          >
            {{ scope.row.isSubmit }}
          </el-button>
          <el-button v-else class="num-btn" type="text" disabled> -- </el-button>
        </template>
      </el-table-column>
      <el-table-column prop="friendsNum" label="拍摄人" width="70" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.friendsNum"
            class="num-btn"
            type="text"
            @click.stop="onPhotographer(scope.row)"
          >
            {{ scope.row.friendsNum }}
          </el-button>
          <el-button v-else class="num-btn" type="text" disabled> -- </el-button>
        </template>
      </el-table-column>

      <el-table-column prop="erpPhoneStatusName" label="去电结果" width="80">
        <template slot-scope="scope">{{ scope.row.erpPhoneStatusName || '--' }}</template>
      </el-table-column>

      <el-table-column
        prop="subStatusName"
        label="状态"
        width="120"
        sortable="custom"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span
            class="status-name-hover"
            :style="{ color: [5, 8].includes(scope.row.status) ? '#00A199' : '#333333' }"
            @click="handleOperate('serachDetail', scope.row)"
            >{{ scope.row.subStatusName || scope.row.statusName }}</span
          >
        </template>
      </el-table-column>
      <el-table-column prop="friendsNum" label="操作" header-align="right" width="230">
        <template slot-scope="scope">
          <div class="buttons">
            <el-button
              v-if="
                (scope.row.orderProduct || {}).serviceType == 1 &&
                scope.row.subStatus === 4 &&
                (!scope.row.buttonV2Dto.hiddQrddBtn || scope.row.sourceFrom == 3)
              "
              type="text"
              @click.stop="confirmStore(scope.row)"
            >
              确认到店
            </el-button>
            <!-- <el-button
              v-if="
                scope.row.buttonV2Dto && scope.row.buttonV2Dto.qrksfwBtn && scope.row.status === 3
              "
              type="text"
              @click.stop="orderStartService(scope.row)"
            >
              开始服务
            </el-button> -->
            <el-button
              v-if="[3, 4, 7].includes(scope.row.status)"
              type="text"
              @click.stop="arrangeShooting(scope.row)"
            >
              安排人员
            </el-button>
            <el-button type="text" @click.stop="onPhotoPlan(scope.row)"> 拍摄规划 </el-button>

            <el-dropdown @command="onCommand">
              <el-button style="margin-left: 10px" type="text"> 更多 </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-if="menus.find((e) => e.nodeCode == 'GY_ORDER_SERVICELIST_DETAIL')"
                  :command="{ key: 'serachDetail', row: scope.row }"
                >
                  查看详情
                </el-dropdown-item>
                <el-dropdown-item
                  v-if="menus.find((e) => e.nodeCode == 'SHOOT_SERVICE')"
                  :command="{ key: 'gotoOrderDetail', row: scope.row }"
                >
                  关联服务单
                </el-dropdown-item>
                <el-dropdown-item
                  :divided="menus.find((e) => e.nodeCode == 'GY_ORDER_SERVICELIST_DETAIL')"
                  :command="{ key: 'gotoAddPayOrder', row: scope.row }"
                >
                  新增订单
                </el-dropdown-item>
                <el-dropdown-item
                  v-if="[3, 4, 5, 8].includes(scope.row.status)"
                  :command="{ key: 'addProduct', row: scope.row }"
                >
                  加购产品
                </el-dropdown-item>
                <el-dropdown-item
                  v-if="[2, 3, 4, 9].includes(scope.row.status)"
                  divided
                  :command="{ key: 'openSalesConsultant', row: scope.row }"
                >
                  安排销售顾问
                </el-dropdown-item>
                <el-dropdown-item
                  v-if="scope.row.writeOffStatus === 1"
                  :command="{ key: 'openQRCode', row: scope.row }"
                >
                  核销二维码
                </el-dropdown-item>
                <el-dropdown-item
                  v-if="
                    scope.row.buttonV2Dto &&
                    scope.row.buttonV2Dto.qrwcqbfwBtn &&
                    scope.row.sourceFrom !== 3
                  "
                  divided
                  :command="{ key: 'onConfrimAllService', row: scope.row }"
                >
                  完成全部服务
                </el-dropdown-item>
                <el-dropdown-item
                  v-if="(scope.row.buttonV2Dto || {}).shareEvaBtn"
                  divided
                  :command="{ key: 'goSharing', row: scope.row }"
                >
                  评价分享
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 子服务单 -->
    <new-arrange-shooting
      v-if="arrangeShootingVisible"
      :base-product-type="baseProductType"
      :current-data="currentData"
      :dialog-visible.sync="arrangeShootingVisible"
      @reload="refresh"
    />
    <el-dialog title="核销二维码" :visible.sync="qrcodeVisible" width="300px">
      <div v-loading="qrcodeLoading" class="qrcode">
        <el-image
          style="width: 150px; height: 150px"
          :src="'data:image/png;base64,' + barcode"
          fit="cover"
        />
        <div>该核销码仅于客户无法出示核销码等异常场景下使用</div>
      </div>
    </el-dialog>
    <evaluationSharing
      :visible.sync="isShow"
      :service-id="currentData.serviceId"
      @reload="refresh"
    />
  </div>
</template>

<script>
import evaluationSharing from '@/views/orderMgt/orderList/components/evaluationSharing.vue'
import newArrangeShooting from '@/views/orderMgt/orderList/components/newArrangeShooting.vue'
import { checkAdditionState, enterFinishServer, getOrderServiceOffDetail } from '@/api/order'
import { confirmStore, orderStartServe } from '@/api/order.js'

export default {
  components: {
    newArrangeShooting,
    evaluationSharing,
  },
  props: {
    orderData: {
      type: Array,
      default: () => [],
    },
    isOrderData: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menus: [],
      arrangeShootingVisible: false,
      baseProductType: '',
      currentData: {},
      qrcodeVisible: false,
      barcode: '',
      qrcodeLoading: false,
      isShow: false,
    }
  },
  created() {
    this.menus = JSON.parse(window.sessionStorage.getItem('btnPermissions'))
      ? JSON.parse(window.sessionStorage.getItem('btnPermissions'))
      : []
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      if ((row.subStatus >= 6 && row.subStatus !== 31) || row.subStatusVideo >= 6)
        return 'underway-row'
      if (row.isCompletedDispatch === 1) {
        return 'success-row'
      } else {
        return 'default-row'
      }
    },
    arrangeShooting(row) {
      this.baseProductType = this.getBaseProductType(row.subStatus)
      this.currentData = row
      this.arrangeShootingVisible = true
    },
    getBaseProductType(e) {
      switch (e) {
        case 2:
          return 1
        case 3:
          return 1
        case 4:
          return 1
        case 6:
          return 1
        case 12:
          return 5
        case 17:
          return 6
        case 20:
          return 7
        case 23:
          return 8
        case 26:
          return 14
        default:
          break
      }
    },
    handleOperate(key, item) {
      this.$emit('handleOperate', key, item)
    },

    onCommand({ key, row }) {
      const arr = ['serachDetail', 'gotoOrderDetail', 'addProduct']

      if (arr.includes(key)) {
        return this.handleOperate(key, row)
      }
      this[key](row)
    },
    // 评价分享
    goSharing(row) {
      this.currentData = row
      this.isShow = true
    },
    gotoAddPayOrder(e) {
      this.$emit('gotoAddPayOrder', e)
    },
    openSalesConsultant(e) {
      this.$emit('openSalesConsultant', e)
    },
    onPhotoPlan(e) {
      this.$emit('onPhotoPlan', e)
    },
    refresh() {
      this.$emit('refresh')
    },
    gotoBeforeShoot(e) {
      this.$emit('gotoBeforeShoot', e)
    },
    onPhotographer(e) {
      this.$emit('onPhotographer', e)
    },
    gotoCustomerDetail(e) {
      this.$emit('gotoCustomerDetail', e)
    },

    sortChange(e) {
      console.log(e)
      const des = e.order === 'descending' ? 'desc' : 'asc'
      if (e.prop === 'appointmentShootDatetime') {
        return this.$emit('sortChange', 'appointment_shoot_datetime', des)
      }
      if (e.prop === 'subStatusName') {
        return this.$emit('sortChange', 'subStatus', des)
      }
    },
    async openQRCode(row) {
      this.qrcodeVisible = true
      this.qrcodeLoading = true
      const reqObj = {
        data: {
          // orderServiceId: item.orderServiceId,
          serviceId: row.serviceId,
          orderFlowType: row.status > 1 ? null : 1,
          serviceNo: row.serviceNo,
          writeOffType: 1,
        },
      }

      try {
        const res = await getOrderServiceOffDetail(reqObj)

        if (res) {
          this.barcode = res.data.barcode
        }
      } catch (error) {
        console.log(error)
      }
      this.qrcodeLoading = false
    },
    confirmStore(row) {
      confirmStore({
        data: { serviceId: row.serviceId },
      })
        .then(() => {
          this.$message.success('确认到店成功~')
          this.refresh()
        })
        .catch((e) => {
          this.$message.error(e.description || '确认到店失败~')
        })
    },
    orderStartService(row) {
      orderStartServe({
        data: {
          serviceItemsId: row.serviceItemId,
          storeId: row.appointmentStroeId,
          serviceId: row.serviceId,
        },
      })
        .then(() => {
          this.$message.success('开始服务成功~')
          this.refresh()
        })
        .catch((e) => {
          this.$message.error(e.description || '开始服务失败~')
        })
    },
    async onConfrimAllService(row) {
      const res = await checkAdditionState({
        data: {
          serviceId: row.serviceId,
        },
      })
      // 判断是否可以确认完成全部服务
      if (Number(res.data) === 0) {
        try {
          await this.$confirm(
            '是否确认完成全部服务？确认完成全部服务后，本服务订单将完成服务，进入评价环节',
            '完成全部服务确认',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            },
          )
          await enterFinishServer({
            data: {
              serviceId: row.serviceId,
              actionType: 1,
            },
          })
          this.$message.success('确认成功!')
          this.refresh()
        } catch (error) {}
        return
      }
      try {
        await this.$confirm(
          '当前服务订单的关联加购订单有待付款或售后中的实物产品，请在产品已付款或已取消或售后完成后再确认完成全部服务',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          },
        )
        this.$router.push({
          path: '/orderDetail',
          query: {
            orderServiceId: row.orderServiceId,
            serviceId: row.serviceId,
            isServer: true,
          },
        })
      } catch (error) {}
    },
  },
}
</script>

<style lang="scss" scoped>
.contact-link {
  cursor: pointer;
  color: #409eff;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.contact-level {
  font-size: 12px;
  width: 14px;
  height: 14px;
  background: #ff810f;
  color: #fff;
  border-radius: 2px;
  display: inline-block;
  line-height: 14px;
  text-align: center;
  margin-right: 4px;
}
.service-box {
  display: inline-flex;
  flex-wrap: wrap;
}
.service-item {
  padding-right: 10px;
  flex-shrink: 0;
  &__title {
    color: #999999;
    font-size: 12px;
    white-space: nowrap;
    line-height: 1.4;
  }
  &__name {
    font-size: 12px;
    white-space: nowrap;
    line-height: 1.5;
    text-overflow: ellipsis;
    width: 54px;
    overflow: hidden;
  }
}
.buttons {
  display: flex;
  justify-content: flex-end;
}
.qrcode {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  & > div:nth-child(2) {
    margin-top: 10px;
    font-size: 14px;
    color: #999;
    text-align: center;
  }
}
.num-btn {
  display: block;
  width: 100%;
  height: 100%;
  text-align: left;
}
.status-name-hover {
  cursor: pointer;
  &:hover {
    color: #409eff !important;
  }
}
.popover__title {
  font-weight: 700;
  font-size: 14px;
  color: #333;
}
.popover__item {
  font-weight: 500;
  font-size: 12px;
  color: #666;
  line-height: 18px;
  display: flex;
  align-items: center;
  &::before {
    content: '';
    width: 4px;
    height: 4px;
    border-radius: 50%;
    display: inline-block;
    background: #409eff;
    margin-right: 4px;
  }
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
  background: transparent;
}
::v-deep .el-table__body tr:hover > td {
  position: relative;
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.06);
    pointer-events: none;
  }
}
</style>
<style lang="scss">
.el-table .default-row {
  background: #fff;
}

.el-table .success-row {
  background: #caebc9;
}
.el-table .underway-row {
  background: #dcdbf1;
}
</style>
