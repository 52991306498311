<template>
  <el-scrollbar ref="elScroll" class="scrollBox" style="height: 750px">
    <ul v-for="(item, i) in orderData" :key="i" class="order-list">
      <li class="list-item">
        <div class="flex item-header" :style="getCurrentBgC(item.status)">
          <div class="item-header-left">
            <div v-if="item.status !== 1" class="item-header-l">
              <span>派单时间：{{ item.dispatchTime }}</span>
              <span>服务单号：{{ item.serviceNo }}</span>
              <span>完成服务时间：{{ item.finishTime || '--' }}</span>
              <span v-if="!!item.finishAssessTime">完成评价时间：{{ item.finishAssessTime }}</span>
              <span :class="item.bizLineId == '2' ? 'bizLine__jpg' : 'bizLine__dpll'">{{
                item.bizLineId == '2' ? 'JPG' : '大片来了'
              }}</span>
            </div>
            <div v-else class="item-header-l">
              <span>创建时间：{{ item.createTime }}</span>
              <span>服务单号：{{ item.serviceNo }}</span>
              <span :class="item.bizLineId == '2' ? 'bizLine__jpg' : 'bizLine__dpll'">{{
                item.bizLineId == '2' ? 'JPG' : '大片来了'
              }}</span>
            </div>
          </div>
          <div class="item-header-r">
            <span v-if="item.writeOffStatus" class="write-off">{{
              item.writeOffStatus === 1 ? '待核销' : '已核销'
            }}</span>
            <el-popover
              v-if="item.status > 0 && item.writeOffStatus === 1"
              placement="bottom"
              trigger="click"
              @show="popoverShow(item)"
              @after-leave="popoverHide"
            >
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  width: 144px;
                  margin: auto;
                "
              >
                <div v-loading="!barcode">
                  <el-image :src="'data:image/png;base64,' + barcode" />
                </div>
                <div style="text-align: center">该核销码仅于客户无法出示核销码等异常场景下使用</div>
              </div>

              <div slot="reference" style="display: inline-block">
                <svg
                  t="1690520823809"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="2375"
                  width="12"
                  height="12"
                >
                  <path
                    d="M874.666667 553.877333V746.666667h-192v-128.021334h-64v258.986667h-64V554.666667h192v127.978666h64v-128.768h64zM469.333333 554.666667v320H149.333333V554.666667h320z m405.333334 256v64h-192v-64h192z m-469.333334-192h-192v192h192v-192z m-64 64v64h-64v-64h64z m128-533.333334v320H149.333333V149.333333h320z m405.333334 0v320H554.666667V149.333333h320z m-469.333334 64h-192v192h192v-192z m405.333334 0h-192v192h192v-192z m-469.333334 64v64h-64v-64h64z m405.333334 0v64h-64v-64h64z"
                    fill="#333333"
                    p-id="2376"
                  />
                </svg>
              </div>
            </el-popover>
            {{ item.statusName }}
          </div>
        </div>
        <div class="flex item-content">
          <div class="flex item-common item-content-container">
            <!-- <el-image class="item-content-img" :src="item.coverPictureUrl" alt="">
                  <span class="el-image-error" slot="error">暂无图片</span>
                </el-image> -->

            <ViewSamplePhotos
              :key="item.serviceId"
              :cover-picture-url="item.coverPictureUrl"
              :order-sample-pic-list="item.orderSamplePicList"
              :questionnaire-sample-pic-list="item.questionnaireSamplePicList"
              :qphoto-regist-sample-pic-list="item.qphotoRegistSamplePicList"
            />

            <div class="item-name">
              <div>{{ (item.orderProduct || {}).productName }}</div>
              <div style="margin-top: 10px">
                服务类型：{{ (item.orderProduct || {}).serviceTypeName }}
              </div>
              <div style="margin-top: 10px">
                所属品牌：{{ item.brandName ? item.brandName : '暂无品牌' }}
              </div>
            </div>
          </div>
          <div v-if="item.status === 1" class="item-common">
            <div class="item-lable">关联单号：</div>
            <div class="item-text">
              {{ item.orderNo }}
            </div>
          </div>
          <div v-if="item.status === 1" class="item-common">
            <div class="item-lable">所属用户：</div>
            <el-button type="text" @click="gotoCustomerDetail(item)">
              {{ item.customerName + ' | ' + item.createOrderPhone }}
            </el-button>
            <!-- <div class="item-text" @click="gotoCustomerDetail(item)">{{ item.customerName }}</div>
                <div class="item-text" @click="gotoCustomerDetail(item)">{{ item.createOrderPhone }}</div> -->
          </div>
          <div class="item-common">
            <div class="item-lable">预约信息：</div>
            <div class="item-customer">
              <el-button type="text" @click="gotoCustomerDetail(item)">
                {{ item.contactPersonName + ' | ' + item.contactPhoneNumber }}
              </el-button>
              <span v-if="item.customerLevelName" class="customer-grade">{{
                item.customerLevelName
              }}</span>
            </div>
            <!-- <div class="item-text" @click="gotoCustomerDetail(item)">{{ item.contactPersonName }}</div>
                <div class="item-text" @click="gotoCustomerDetail(item)">{{ item.contactPhoneNumber }}</div> -->
            <div class="item-text">
              {{ item.appointmentShootDatetime }}
            </div>
            <div class="item-text" style="white-space: nowrap">
              <el-button
                v-if="item.isEntrance"
                :type="item.isSubmit ? 'primary' : ''"
                :style="{
                  background: item.isSubmit ? '#2861e2' : '',
                  borderColor: item.isSubmit ? '#2861e2' : '',
                }"
                @click="gotoBeforeShoot(item)"
              >
                新品推荐
              </el-button>
              <el-button v-if="item.status >= 2" type="primary" @click="onPhotographer(item)">
                拍摄人
                <span v-if="item.friendsNum">({{ item.friendsNum }})</span>
              </el-button>
              <el-button v-if="item.status >= 2" type="primary" @click="onPhotoPlan(item)">
                拍摄规划
              </el-button>
            </div>
          </div>
          <div class="item-common">
            <div class="item-text">服务金额：¥{{ item.payAmount }}</div>
            <div class="item-text">加购金额：¥{{ item.addRefundAmount }}</div>
            <div class="item-text">库存金额：¥{{ item.inventoryAmount }}</div>
            <div class="item-text">服务总金额：¥{{ item.totalAmount }}</div>
          </div>
          <div class="item-common">
            <!-- <div class="item-text">服务人员（{{ item.arrangedServiceNum }}）</div> -->
            <div v-if="item.managerName" class="item-sample">
              <div class="item-text" style="color: #aeb0b1; white-space: nowrap">销售顾问：</div>
              <div class="item-value">
                {{ item.managerName }}
              </div>
            </div>
            <div class="item-text" style="color: #aeb0b1">服务人员</div>
            <template v-for="(serviceItem, index) in item.serviceItems">
              <div
                v-if="serviceItem.serviceUserName && serviceItem.state != 1"
                :key="index"
                class="item-text item-text-service"
              >
                <span class="baseServiceTypeName">{{ serviceItem.baseServiceTypeName }}</span>
                <span class="avatar-container">
                  <el-image
                    class="avatar"
                    :src="
                      serviceItem.serviceUserAvatarUrl
                        ? serviceItem.serviceUserAvatarUrl
                        : require('@/static/default.png')
                    "
                  />
                  <el-tooltip v-if="serviceItem.total" effect="dark" placement="top">
                    <div slot="content" style="width: 120px">
                      <div style="font-weight: bold; font-size: 14px">
                        今日已有{{ serviceItem.total }}个任务
                      </div>
                      <div style="margin-top: 10px; color: rgba(255, 255, 255, 0.75)">
                        服务开始时间：
                      </div>

                      <div style="display: flex; margin-top: 6px; flex-wrap: wrap">
                        <div
                          v-for="(timeItem, ind) in serviceItem.serverUserTaskTimes"
                          :key="ind"
                          class="tooltip-time-item"
                          style="
                            padding: 4px 6px;
                            border-radius: 2px;
                            display: inline-block;
                            background: rgba(233, 238, 249, 0.3);
                            color: #ffffff;
                            flex-shrink: 0;
                            margin-top: 6px;
                            width: 45%;
                            text-align: center;
                            margin-right: 5%;
                            box-sizing: border-box;
                          "
                        >
                          {{ timeItem }}
                        </div>
                      </div>
                    </div>
                    <span class="brage">{{ serviceItem.total }}</span>
                  </el-tooltip>
                </span>

                <span class="serviceUserName">{{ serviceItem.serviceUserName }}</span>
              </div>

              <div
                v-if="!serviceItem.serviceUserName"
                :key="index"
                class="item-text item-text-service"
              >
                <span class="baseServiceTypeName">{{ serviceItem.baseServiceTypeName }}</span>
                /
              </div>

              <div
                v-if="serviceItem.state == 1"
                :key="index"
                class="item-text item-text-service remind-row"
              >
                <div style="display: flex; align-items: center">
                  <span class="baseServiceTypeName">{{ serviceItem.baseServiceTypeName }}</span>
                  <span class="avatar-container">
                    <el-image
                      class="avatar"
                      :src="
                        serviceItem.serviceUserAvatarUrl
                          ? serviceItem.serviceUserAvatarUrl
                          : require('@/static/default.png')
                      "
                    />
                    <el-tooltip v-if="serviceItem.total" effect="dark" placement="top">
                      <div slot="content">
                        <div style="font-weight: bold; font-size: 14px">
                          今日已有{{ serviceItem.total }}个任务
                        </div>
                        <div style="margin-top: 10px; color: rgba(255, 255, 255, 0.75)">
                          服务开始时间：
                        </div>

                        <div style="display: inline-flex; flex-direction: column; margin-top: 6px">
                          <div
                            v-for="(timeItem, ind) in serviceItem.serverUserTaskTimes"
                            :key="ind"
                            class="tooltip-time-item"
                            style="
                              padding: 4px 6px;
                              border-radius: 2px;
                              background: rgba(233, 238, 249, 0.2);
                              display: inline-block;
                            "
                          >
                            {{ timeItem }}
                          </div>
                        </div>
                      </div>
                      <span class="brage">{{ serviceItem.total }}</span>
                    </el-tooltip>
                  </span>
                  <span class="serviceUserName" style="color: rgb(181, 187, 197)">{{
                    serviceItem.serviceUserName
                  }}</span>
                </div>
                <el-button type="text" size="large" @click="remind(serviceItem)"> 提醒 </el-button>
              </div>
            </template>
          </div>
          <div class="item-common btnRight">
            <div class="item-content-butoon">
              <el-button
                v-if="menus.find((e) => e.nodeCode == 'GY_ORDER_SERVICELIST_DETAIL')"
                class="button-detail"
                type="primary"
                @click="handleOperate('serachDetail', item)"
              >
                查看详情
              </el-button>
              <el-button
                v-if="[3, 4, 5, 8].includes(item.status)"
                class="refuse"
                type="primary"
                @click="handleOperate('addProduct', item)"
              >
                加购产品
              </el-button>
              <el-button
                v-if="item.buttonV2Dto && item.buttonV2Dto.selectInventoryBtn"
                size="small"
                type="primary"
                @click="onShowSubDialog(item)"
              >
                选择库存散件
              </el-button>
              <template v-if="item.status !== 1">
                <!-- <el-button class="button-detail" type="primary" @click="handleOperate('serachDetail', item)">查看详情</el-button> -->
                <!-- <el-button type="primary" @click="lookCode(item)" v-if="item.orderProduct.serviceType == 2 && (item.status === 3 || item.status === 4)">查看验证码</el-button> -->
                <el-button
                  v-if="item.status === 2"
                  type="primary"
                  @click="handleOperate('enterOrder', item)"
                >
                  确认接单
                </el-button>
                <el-button
                  v-if="item.status === 2"
                  class="refuse"
                  type="primary"
                  @click="handleOperate('refuseOrder', item)"
                >
                  拒绝接单
                </el-button>

                <!-- <el-button class="bg00" @click="handleOperate('enterStop', item)" v-if="!item.buttonV2Dto.hiddQrddBtn && item.orderProduct.serviceType == 1 && item.subStatus === 4">
                    确认到店
                  </el-button> -->

                <el-button
                  v-if="
                    (item.orderProduct || {}).serviceType == 1 &&
                    item.subStatus === 4 &&
                    (!item.buttonV2Dto.hiddQrddBtn || item.sourceFrom == 3)
                  "
                  class="bg00"
                  @click="handleOperate('enterStop', item)"
                >
                  确认到店
                </el-button>

                <!-- <el-button type="primary" @click="handleOperate('enterServer', item)" v-if="item.orderProduct.serviceType == 1 && item.status === 4">确认完成服务</el-button> -->
                <el-button class="refuse" type="primary" @click="gotoAddPayOrder(item)">
                  新增订单
                </el-button>
                <el-button
                  v-if="item.status != 2"
                  type="primary"
                  @click="handleOperate('uploadImg', item)"
                >
                  上传照片/视频
                </el-button>
                <el-button
                  v-if="
                    item.status === 2 || item.status === 3 || item.status === 4 || item.status === 9
                  "
                  class="refuse"
                  type="primary"
                  @click="openSalesConsultant(item)"
                >
                  安排销售顾问
                </el-button>
              </template>

              <el-button
                v-if="
                  item.status == 1 && menus.find((e) => e.nodeCode == 'GY_ORDER_CLICKSUBSCRIBE')
                "
                type="primary"
                @click="subscribe(item)"
              >
                预约门店
              </el-button>
            </div>

            <Btns v-if="item.status !== 1" type="list" :item="item" @refresh="refresh" />
          </div>
        </div>
      </li>
    </ul>
    <el-empty v-if="!isOrderData" :image-size="200" />
  </el-scrollbar>
</template>

<script>
import Btns from '../btns.vue'
export default {
  components: {
    Btns,
  },
  props: {
    orderData: {
      type: Array,
      default: () => [],
    },
    isOrderData: {
      type: Boolean,
      default: false,
    },
    barcode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      menus: [],
    }
  },
  computed: {
    getCurrentBgC() {
      return (status) => {
        switch (status) {
          case 1:
            return { backgroundColor: 'rgb(252, 239, 236)', color: 'rgb(249, 80, 81)' }
          case 2:
            return { backgroundColor: '#fcefec', color: '#E86247' }
          case 3:
            return { backgroundColor: '#FCF3DE', color: '#E3904A' }
          case 4:
            return { backgroundColor: '#E9EFFC', color: '#2861E2' }
          case 8:
            return { backgroundColor: '#E5F5F4', color: '#00A199' }
          default:
            // case 5:
            // case 7:
            return { backgroundColor: '#EDEDED', color: '#000000' }
        }
      }
    },
    calculateAllMoney() {
      return (item) => {
        return (
          Number(item.payAmount) +
          Number(item.addAmount) -
          Number(item.refundAmount)
        ).toFixed(2)
      }
    },
  },
  created() {
    this.menus = JSON.parse(window.sessionStorage.getItem('btnPermissions'))
      ? JSON.parse(window.sessionStorage.getItem('btnPermissions'))
      : []
  },
  methods: {
    popoverShow(e) {
      this.$emit('popoverShow', e)
    },
    gotoCustomerDetail(e) {
      this.$emit('gotoCustomerDetail', e)
    },
    gotoBeforeShoot(e) {
      this.$emit('gotoBeforeShoot', e)
    },
    onPhotographer(e) {
      this.$emit('onPhotographer', e)
    },
    onPhotoPlan(e) {
      this.$emit('onPhotoPlan', e)
    },
    remind(e) {
      this.$emit('remind', e)
    },
    handleOperate(key, item) {
      this.$emit('handleOperate', key, item)
    },
    gotoAddPayOrder(e) {
      this.$emit('gotoAddPayOrder', e)
    },
    openSalesConsultant(e) {
      this.$emit('openSalesConsultant', e)
    },
    subscribe(e) {
      this.$emit('subscribe', e)
    },
    refresh() {
      this.$emit('refresh')
    },
    popoverHide() {
      this.$emit('popoverHide')
    },
    onShowSubDialog(e) {
      this.$emit('showSub', e)
    },
  },
}
</script>

<style lang="scss" scoped>
.order-list {
  margin-bottom: 20px;
  border: 1px solid #e0e0e0;

  .list-item {
    min-height: 194px;
    .item-header {
      padding: 13px 17px;
      justify-content: space-between;
      color: #363f47;
      .item-header-l {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        span {
          margin-right: 30px;
          white-space: nowrap;
          line-height: 1.5;
        }
        span:last-child {
          margin-right: unset;
        }
      }
      .item-header-r {
        font-weight: bold;
        font-size: 18px;
        white-space: nowrap;
        // color: #333 !important;
      }
    }
    .item-content {
      .item-common {
        flex: 1;
        padding: 16px;
        border-right: 1px solid #e0e0e0;
      }
      .item-common:last-child {
        border: none;
      }
      .item-lable {
        margin-bottom: 12px;
        font-weight: 500;
      }
      .item-sample {
        display: flex;
        align-items: center;
      }
      .item-value {
        color: #333;
        margin-bottom: 12px;
      }
      .item-text {
        margin-bottom: 12px;

        .avatar-container {
          position: relative;
          height: 20px;
          width: 20px;
          border-radius: 10px;
          margin-right: 10px;
        }

        .avatar {
          height: 20px;
          width: 20px;
          border-radius: 10px;
        }
        .brage {
          position: absolute;
          top: -15px;
          right: -18px;
          background: #2861e2;
          border-radius: 16px;
          border: 2px solid #fff;
          color: #fff;
          font-size: 20px;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          transform: scale(0.45);
        }

        &-service {
          display: flex;
          align-items: center;

          .baseServiceTypeName {
            margin-right: 16px;
          }
        }
      }
      .remind-row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        ::v-deep .el-button {
          padding: unset;
        }
      }
      .item-img-text {
        margin-bottom: 12px;
        color: #666666;
      }
      .item-content-container {
        // align-items: center;
        padding: 34px 12px;
        .item-content-img {
          width: 80px;
          height: 80px;
          margin-right: 12px;
          vertical-align: middle;
        }
        .item-name {
          flex: 1;
        }
      }
      .btnRight {
        max-width: 190px;
        box-sizing: border-box;
      }
      .item-content-butoon {
        ::v-deep .el-button {
          display: block;
          margin: 0 0 12px 0;
          width: 100%;
        }

        .button-detail {
          background-color: #e9effc;
          color: #2861e2;
          border: 1px solid #e9effc;
          &:hover {
            opacity: 0.8;
          }
        }
        .refuse {
          background-color: #fff;
          color: #2861e2;
          border: 1px solid #2861e2;
          &:hover {
            opacity: 0.8;
          }
        }
      }
      .item-customer {
        .customer-grade {
          display: inline-flex;
          font-size: 12px;
          width: 14px;
          height: 14px;
          background: #f33f3f;
          color: #fff;
          border-radius: 2px;
          justify-content: center;
          align-items: center;
          margin-left: 4px;
        }
      }
    }
  }
}
.item-header-left {
  display: flex;
  flex-wrap: wrap;
}
.write-off {
  font-size: 14px;
  margin-right: 10px;
}
.bizLine__jpg {
  height: 18px;
  padding: 0 2px;
  border-radius: 2px;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #ff8ab7;
  border: 1px solid #ff8ab7;
  margin-left: 10px;
  line-height: 1;
  font-size: 10px;
  white-space: nowrap;
}
.bizLine__dpll {
  height: 18px;
  padding: 0 2px;
  border-radius: 2px;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #ff7027;
  border: 1px solid #ff7027;
  margin-left: 10px;
  font-size: 10px;
  white-space: nowrap;
}
</style>
