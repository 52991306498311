import request from '@/utils/request'

export function getChannelTree(data) {
  return request({
    url: 'misc-web-api/admin/channel/getChannelTree',
    method: 'post',
    data,
  })
}
export function getChannel(data) {
  return request({
    url: 'misc-web-api/admin/channel/getChannelList',
    method: 'post',
    data,
  })
}
