<template>
  <div ref="orderList" class="order-list-container">
    <ul class="flex order-tab">
      <li
        v-for="(item, i) in tabList"
        :key="i"
        class="tab-list"
        :class="i === tabIndex ? 'tab-list-checked' : ''"
        :style="{
          display:
            i == 1 && !menus.find((e) => e.nodeCode == 'GY_ORDER_WAITSUBSCRIBELIST') ? 'none' : '',
        }"
        @click="handleTab(item, i)"
      >
        <span>{{ item.name }}</span>
        <span v-show="!!item.statusCount"
          >({{ item.statusCount && item.statusCount > 99 ? '99+' : item.statusCount }})</span
        >
      </li>
    </ul>
    <div class="flex search-form-container">
      <el-form ref="searchForm" :model="queryData" inline label-width="120px">
        <el-form-item v-if="tabList[tabIndex].status === 1" label="联系人手机号:">
          <el-input v-model="queryData.data.contactPhoneNumber" placeholder="请输入" />
        </el-form-item>
        <el-form-item v-if="tabList[tabIndex].status === 1" label="下单手机号:">
          <el-input v-model="queryData.data.createOrderPhone" placeholder="请输入" />
        </el-form-item>
        <el-form-item v-if="tabList[tabIndex].status !== 1" label="服务类型:">
          <el-select v-model="queryData.data.serviceType" style="width: 184px" placeholder="请选择">
            <el-option label="全部" value="" />
            <el-option label="到店拍摄" value="1" />
            <el-option label="上门拍摄" value="2" />
          </el-select>
        </el-form-item>
        <el-form-item label="业务线：">
          <el-select v-model="queryData.data.bizLineId" style="width: 184px" placeholder="请选择">
            <el-option
              v-for="item in bizLineIdOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item v-if="tabList[tabIndex].status !== 1" label="预约手机号:">
          <el-input v-model="queryData.data.phone" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="服务人员:">
          <el-input v-model="queryData.data.serviceUserName" placeholder="请输入姓名" />
        </el-form-item>
        <el-form-item label="服务单号:">
          <el-input v-model="queryData.data.serviceNo" placeholder="请输入" />
        </el-form-item>
        <el-form-item v-if="tabList[tabIndex].status === 1" label="关联收款单号:">
          <el-input v-model="queryData.data.orderNo" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="销售顾问:">
          <el-select
            v-model="queryData.data.managerId"
            style="width: 184px"
            filterable
            remote
            reserve-keyword
            size="small"
            placeholder="请输入销售顾问姓名"
            :remote-method="searchRemoteMethod"
          >
            <el-option
              v-for="item in searchstoreUserList"
              :key="item.userId"
              :label="item.userName"
              :value="item.userId"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="来源渠道:">
          <selected-channel v-model="queryData.data.channelId" width="184px" />
        </el-form-item>
        <el-form-item v-if="tabList[tabIndex].status !== 1" label="预约时间:">
          <el-date-picker
            v-model="appointmentDates"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            :picker-options="subscribePickerOptions"
          />
        </el-form-item>
        <el-form-item v-if="tabList[tabIndex].status !== 1" label="完成服务时间:">
          <el-date-picker
            v-model="successServerTimes"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
          />
        </el-form-item>
        <el-form-item v-if="tabList[tabIndex].status !== 1" :key="1" label="派单时间:">
          <el-date-picker
            v-model="appointmentOrderDates"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
          />
        </el-form-item>
        <el-form-item label="开始服务时间:">
          <el-date-picker
            v-model="serviceBeginTime"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
          />
        </el-form-item>
        <el-form-item label="接单时间:">
          <el-date-picker
            v-model="confirmOrderTime"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
          />
        </el-form-item>
        <el-form-item style="margin-left: 20px">
          <!-- <el-button type="text" @click="handleUnfold">{{ moreConditionName }}</el-button> -->
          <el-button type="primary" :loading="orderLoading" @click="handleSerch"> 查询 </el-button>

          <el-button icon="el-icon-refresh-left" @click="handleReset"> 重置 </el-button>
        </el-form-item>
        <!--  <transition-group name="fade" style="display: block">
          <template v-if="isMoreCondition">
            <el-form-item label="派单时间:" :key="1">
              <el-date-picker v-model="appointmentOrderDates" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
          </template>
        </transition-group> -->
      </el-form>
    </div>
    <div class="colour">
      <div
        class="iconfont icon-liebiao1 colour__table"
        :class="colour === 'table' && 'colour--checked'"
        @click="colourChange('table')"
      />
      <div
        class="iconfont icon-fuwu-active colour__list"
        :class="colour === 'list' && 'colour--checked'"
        @click="colourChange('list')"
      />
    </div>
    <div v-loading="orderLoading" class="order-list">
      <div class="order-box">
        <transition name="el-fade-in-linear">
          <order-table
            v-show="colour === 'table'"
            :order-data="orderData"
            :barcode="barcode"
            @sortChange="sortChange"
            @gotoCustomerDetail="gotoCustomerDetail"
            @onPhotographer="onPhotographer"
            @onPhotoPlan="onPhotoPlan"
            @gotoBeforeShoot="gotoBeforeShoot"
            @handleOperate="handleOperate"
            @gotoAddPayOrder="gotoAddPayOrder"
            @openSalesConsultant="openSalesConsultant"
            @refresh="getTableList"
            @showSub="showSub"
          />
        </transition>
        <transition name="el-fade-in-linear">
          <order-list
            v-show="colour === 'list'"
            :order-data="orderData"
            :is-order-data="isOrderData"
            :barcode="barcode"
            @popoverShow="popoverShow"
            @gotoCustomerDetail="gotoCustomerDetail"
            @gotoBeforeShoot="gotoBeforeShoot"
            @onPhotographer="onPhotographer"
            @onPhotoPlan="onPhotoPlan"
            @remind="remind"
            @handleOperate="handleOperate"
            @gotoAddPayOrder="gotoAddPayOrder"
            @openSalesConsultant="openSalesConsultant"
            @subscribe="subscribe"
            @popoverHide="popoverHide"
            @refresh="getTableList"
            @showSub="showSub"
          />
        </transition>
      </div>

      <el-pagination
        class="pagination"
        background
        :current-page="queryData.pageNumber"
        :page-sizes="[10, 30, 50, 70, 100]"
        :page-size="queryData.pageSize"
        :total="total"
        layout="total, prev, pager, next, sizes, jumper"
        @current-change="handlePagination"
        @size-change="handleSizeChange"
      />
    </div>

    <lookCode
      :dialog-visible.sync="dialogVisible"
      :service-no="serviceNo"
      :service-id="serviceId"
    />
    <RefuseOrderPopups
      popups-title="拒绝接单原因"
      :show-state="isRefuseOrder"
      :current-data="currentData"
      @hidePopups="isRefuseOrder = false"
    />
    <SuccessServerPopups
      popups-title="确认完成服务"
      :show-state="isSuccessServer"
      :current-data="currentData"
      @hidePopups="isSuccessServer = false"
    />
    <UploadPhotoPopups
      v-if="isUploadPhoto"
      popups-title="上传照片"
      :show-state="isUploadPhoto"
      :current-data="currentData"
      @hidePopups="isUploadPhoto = false"
    />

    <AddBuyOrder
      popups-title="新增加购产品"
      :show-state.sync="isAddProduct"
      :current-data="currentData"
    />
    <!-- 服务中及以后不在支持编辑 -->
    <SelectRelativesDrawer
      :show-drawer.sync="showDrawer"
      :biz-line-id="photographer.bizLineId"
      :service-id="photographer.serviceId"
      :customer-id="photographer.customerId"
      :user-id="photographer.userId"
      @success="reset"
    />
    <SelectedInventory
      :service-id="currentData.serviceId"
      :show-product.sync="showSubDialog"
      @success="getTableList"
    />

    <el-dialog title="拍摄规划" :visible.sync="showPhotoPlan" width="600px" class="subPlanDialog">
      <el-form v-loading="photoPlanFormLoading" :model="photoPlanForm" size="small">
        <el-form-item label="预约备注" label-width="70px">
          <el-input
            v-model="photoPlanForm.shootRemark"
            placeholder="请填写预约备注"
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 7 }"
            autocomplete="off"
            maxlength="100"
            show-word-limit
          />
          <el-button
            class="submit-button"
            type="primary"
            size="mini"
            @click="onConfrim('shootRemark')"
          >
            提交
          </el-button>
        </el-form-item>
        <el-form-item label="拍摄规划" label-width="70px">
          <el-input
            v-model="photoPlanForm.shootPlan"
            placeholder="请填写本次拍摄规划"
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 7 }"
            autocomplete="off"
            maxlength="100"
            show-word-limit
          />
          <el-button
            class="submit-button"
            type="primary"
            size="mini"
            @click="onConfrim('shootPlan')"
          >
            提交
          </el-button>
        </el-form-item>
        <!-- <el-form-item label="出棚总结" label-width="70px">
          <el-input
            v-model="photoPlanForm.shootSummary"
            placeholder="请填写本次出棚总结"
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 7 }"
            autocomplete="off"
            maxlength="100"
            show-word-limit
          />
          <el-button
            class="submit-button"
            type="primary"
            size="mini"
            @click="onConfrim('shootSummary')"
          >
            提交
          </el-button>
        </el-form-item> -->
      </el-form>
      <!-- <div slot="footer">
        <el-button size="small" @click="onClose">取 消</el-button>
        <el-button size="small" type="primary" @click="onConfrim">提 交</el-button>
      </div> -->
    </el-dialog>
    <el-dialog
      title="选择销售顾问"
      :visible.sync="showSalesConsultant"
      width="500px"
      :close-on-click-modal="false"
    >
      <div>
        <label for="" style="margin-right: 20px">销售顾问</label>
        <el-select
          v-model="managerObj.managerIds"
          filterable
          remote
          :loading="salesConsultantLoading"
          size="small"
          placeholder="请输入销售顾问姓名"
          multiple
          :remote-method="remoteMethod"
        >
          <el-option
            v-for="item in storeUserList"
            :key="item.userId"
            :label="item.userName"
            :value="item.userId"
            :disabled="!item.erpUserId"
          >
            <span>
              {{ item.userName }}
              <span v-if="item.erpUserId" class="erp-user-id">
                (ERP用户编号：{{ item.erpUserId }})
              </span>
            </span>
          </el-option>
        </el-select>
      </div>
      <div slot="footer">
        <el-button size="small" @click="showSalesConsultant = false"> 取 消 </el-button>
        <el-button size="small" type="primary" @click="onSalesConsultantConfrim"> 提 交 </el-button>
      </div>
    </el-dialog>

    <!-- 关联服务单 -->
    <relatedServiceOrder v-model="outerVisible" :details-info="detailsInfo" @reFresh="reFresh" />
  </div>
</template>

<script>
import lookCode from '@/views/orderMgt/orderList/components/lookCode'
import RefuseOrderPopups from './components/refuseOrder'
import SuccessServerPopups from './components/successServer'
import UploadPhotoPopups from './components/uploadPhoto'
import OrderList from './components/order-list/index.vue'
import OrderTable from './components/order-table/index.vue'
import { getColourConfig, setColourConfig } from '@/utils/colour.js'
// fade/zoom 等
import 'element-ui/lib/theme-chalk/base.css'
import relatedServiceOrder from '@/views/orderMgt/orderList/components/relatedServiceOrder.vue'
import SelectedChannel from '@/components/selected-channel/index.vue'

import {
  getServiceListCount,
  getOrderQuery,
  enterOrder,
  enterStore,
  notifyAccept,
  getWaitSubscribe,
  shootPlan,
  storeGetServiceById,
  getOrderServiceOffDetail,
  setServiceManager,
  getSelectStoreUserList,
} from '@/api/order'
import { getStoreId } from '@/utils/auth'
import { getStoreList } from '@/api/shed-space.js'

export default {
  components: {
    RefuseOrderPopups,
    SuccessServerPopups,
    UploadPhotoPopups,
    lookCode,
    OrderList,
    OrderTable,
    relatedServiceOrder,
    SelectedChannel,
  },
  data() {
    const vm = this

    return {
      serviceNo: '',
      serviceId: '',
      dialogVisible: false,
      tabList: [
        { name: '全部', status: '' },
        { name: '待预约', status: 1, statusCount: 0 },
        { name: '待接单', status: 2, statusCount: 0 },
        { name: '待服务', status: 3, statusCount: 0 },
        { name: '服务中', status: 4, statusCount: 0 },
        { name: '待评价', status: 5, statusCount: 0 },
        { name: '已完成', status: 8, statusCount: 0 },
        { name: '已关闭', status: 7, statusCount: 0 },
      ],
      tabIndex: 0,
      isMoreCondition: false,
      moreConditionName: '展开',
      appointmentDates: [],
      successServerTimes: [],
      appointmentOrderDates: [],
      serviceBeginTime: [],
      confirmOrderTime: [],
      isOrderData: true,
      orderLoading: false,
      total: 0,
      queryData: {
        pageNumber: 1,
        pageSize: 50,
        sortField: 'appointment_shoot_datetime',
        sortType: 'asc',
        data: {
          cityId: undefined,
          appointmentShootStartDatetime: undefined,
          appointmentShootEndDatetime: undefined,
          phone: undefined,
          serviceNo: undefined,
          serviceUserName: undefined,
          finishStartTime: undefined,
          finishEndTime: undefined,
          dispatchStartTime: undefined,
          dispatchEndTime: undefined,
          status: undefined,
          contactPhoneNumber: '',
          createOrderPhone: '',
          orderNo: '',
          bizLineId: '',
          channelId: '',
        },
      },
      orderData: [], // 门店分页列表
      isRefuseOrder: false,
      isSuccessServer: false,
      isAddProduct: false,
      isUploadPhoto: false,
      currentData: {},
      menus: [],
      waiSubscribeTotal: 0,
      subscribePickerOptions: {
        shortcuts: [
          {
            text: '后一天',
            onClick(picker) {
              let start = ''
              let end = ''
              let laterDate = null
              if (vm.appointmentDates[1]) {
                laterDate = new Date(
                  new Date(vm.appointmentDates[1]).getTime() + 24 * 60 * 60 * 1000,
                )
              } else {
                laterDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
              }

              const year = laterDate.getUTCFullYear()
              const month =
                laterDate.getMonth() >= 9
                  ? laterDate.getMonth() + 1
                  : `0${laterDate.getMonth() + 1}`
              const day =
                laterDate.getDate() >= 10 ? laterDate.getDate() : `0${laterDate.getDate()}`

              start = `${year}-${month}-${day} 00:00:00`
              end = `${year}-${month}-${day} 23:59:59`

              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
      showDrawer: false,
      photographer: {},
      showPhotoPlan: false,
      photoPlanForm: {},
      photoPlanFormLoading: false,
      barcode: '',
      bizLineIdOptions: [
        {
          label: '大片来了',
          value: '1',
        },
        {
          label: 'JPG',
          value: '2',
        },
      ],
      showSalesConsultant: false,
      managerObj: {
        managerIds: [],
      },
      storeUserList: [],
      salesConsultantLoading: false,
      searchstoreUserList: [],
      colour: 'list',
      serviceIds: [],
      showSubDialog: false,
      isFirstEnter: false, // 是 否第一次进入页面
      outerVisible: false,
      detailsInfo: {},
    }
  },

  created() {
    this.menus = JSON.parse(window.sessionStorage.getItem('btnPermissions'))
      ? JSON.parse(window.sessionStorage.getItem('btnPermissions'))
      : []
    const colour = getColourConfig()
    if (colour) this.colour = colour
    if (this.$route.params.serviceIds) {
      this.serviceIds = JSON.parse(this.$route.params.serviceIds)
      this.isFirstEnter = true
    }

    const resServiceIds = JSON.parse(localStorage.getItem('serviceIds'))
    if (resServiceIds) {
      this.serviceIds = resServiceIds
      this.isFirstEnter = true
    }
  },
  async mounted() {
    this.initAppointmentDates()

    const status = this.$route.query.status
    if (status) {
      this.queryData.data.status = status
      this.tabIndex = 1
    }

    await this.getWaitSubscribeList()
    this.getData()
  },
  beforeDestroy() {
    localStorage.removeItem('serviceIds')
  },
  methods: {
    showSub(e) {
      this.currentData = e
      this.showSubDialog = true
    },
    // 关联服务单
    reFresh(vals) {
      if (vals) {
        this.getData()
      }
    },
    colourChange(e) {
      this.colour = e
      setColourConfig(e)
    },
    searchRemoteMethod(e) {
      this.salesConsultantLoading = true
      getSelectStoreUserList({
        data: {
          userName: e,
          storeId: getStoreId(),
        },
        pageSize: 900,
        pageNumber: 1,
      })
        .then((res) => {
          console.log(res)
          this.searchstoreUserList = res.data
        })
        .catch((e) => {
          console.log(e)
          this.searchstoreUserList = []
        })
    },
    remoteMethod(e) {
      console.log(e)
      this.salesConsultantLoading = true
      getSelectStoreUserList({
        data: {
          postId: '316',
          userName: e,
          storeId: this.managerObj.storeId,
        },
        pageSize: 900,
        pageNumber: 1,
      })
        .then((res) => {
          console.log(res)
          this.storeUserList = res.data
          this.salesConsultantLoading = false
        })
        .catch((e) => {
          console.log(e)
          this.storeUserList = []
          this.salesConsultantLoading = false
        })
    },
    openSalesConsultant(item) {
      this.showSalesConsultant = true
      this.managerObj = {
        managerIds: item.serviceSale.map((v) => v.managerId),
        serviceId: item.serviceId,
        storeId: item.appointmentStroeId,
      }
      this.storeUserList = item.serviceSale.map((v) => ({
        userName: v.managerName,
        userId: v.managerId,
        erpUserId: v.erpUserId,
      }))
    },
    onSalesConsultantConfrim() {
      if (!this.managerObj.managerIds.length) {
        this.$message.error('至少选择一个销售顾问')
        return
      }
      setServiceManager({
        data: this.managerObj,
      })
        .then((res) => {
          console.log(res)
          this.showSalesConsultant = false
          this.managerObj = {}
          this.getData()
          this.$message.success('设置销售顾问成功~')
        })
        .catch((e) => {
          console.log(e)
          this.$message.error('设置销售顾问失败~')
        })
    },
    onClose() {
      this.showPhotoPlan = false
      this.photoPlanForm = {}
    },
    onConfrim(type) {
      // if (!this.photoPlanForm.shootPlan || !this.photoPlanForm.shootRemark) {
      //   return this.$message('请填写拍摄规划或备注');
      // }
      shootPlan({
        data: {
          serviceId: this.photoPlanForm.serviceId,
          shootSummary: type === 'shootSummary' ? this.photoPlanForm.shootSummary : null,
          shootRemark: type === 'shootRemark' ? this.photoPlanForm.shootRemark : null,
          shootPlan: type === 'shootPlan' ? this.photoPlanForm.shootPlan : null,
        },
      })
        .then((res) => {
          console.log(res)
          // this.showPhotoPlan = false;
          this.$message.success('提交成功~')
        })
        .catch((e) => {
          this.$message(e.description)
        })
    },
    onPhotoPlan(item) {
      console.log(item)
      // this.photoPlanForm.serviceId = item.serviceId;
      this.showPhotoPlan = true
      this.photoPlanFormLoading = true
      storeGetServiceById({
        data: item.serviceId,
      })
        .then((res) => {
          console.log(res)
          this.photoPlanForm = {
            serviceId: item.serviceId,
            shootPlan: res.data.shootPlan,
            shootRemark: res.data.shootRemark,
            shootSummary: res.data.shootSummary,
          }
          this.photoPlanFormLoading = false
        })
        .catch((e) => {
          this.$message(e.description)
        })
    },
    gotoCustomerDetail(e) {
      console.log(e)
      const newUrl = `${window.location.origin}/index/chance/clientFollowUp/particulars?id=${e.customerId}&type=supply`

      window.open(newUrl)
      // `/index/chance/clientFollowUp/particulars?id=${this.details.customertReqDto.customerId}`
    },
    reset() {
      this.handleReset()
    },
    onPhotographer(row) {
      this.photographer = row
      this.showDrawer = true
    },
    lookCode(item) {
      this.serviceNo = item.serviceNo
      this.serviceId = item.serviceId
      this.dialogVisible = true
    },
    async getServiceListCount() {
      // 状态 (0, "待付款"),(1, "待预约"),(2, "待服务-待接单"),(3, "待服务-已接单"), (4, "服务中"),(5, "待评价"),(6, "售后中"),(7, "已关闭"),(8, "已完成");
      const tabRes = (await getServiceListCount({ data: { ...this.queryData.data } })).data || []
      this.tabList = this.tabList.map((ma) => {
        tabRes.some((so) => {
          if (so.status === ma.status) {
            if (ma.name === '待预约') {
              ma.statusCount = this.waiSubscribeTotal
              return true
            }
            ma.statusCount = so.count
            return true
          }
        })
        return ma
      })
    },
    getData() {
      this.getServiceListCount()
      this.getTableList()
    },
    sortChange(sortField, sortType) {
      console.log(sortField, sortType)
      this.queryData.sortField = sortField
      this.queryData.sortType = sortType
      this.getTableList()
    },
    async getTableList() {
      this.orderData = []
      try {
        this.orderLoading = true
        const query = JSON.parse(JSON.stringify(this.queryData))
        if (!!this.appointmentDates && this.appointmentDates.length) {
          query.data.appointmentShootStartTime = this.appointmentDates[0]
          query.data.appointmentShootEndTime = this.appointmentDates[1]
        }
        if (!!this.successServerTimes && this.successServerTimes.length) {
          query.data.finishStartTime = this.successServerTimes[0]
          query.data.finishEndTime = this.successServerTimes[1]
        }
        if (!!this.appointmentOrderDates && this.appointmentOrderDates.length) {
          query.data.dispatchStartTime = this.appointmentOrderDates[0]
          query.data.dispatchEndTime = this.appointmentOrderDates[1]
        }
        if (!!this.serviceBeginTime && this.serviceBeginTime.length) {
          query.data.serviceBeginTimeStartTime = this.serviceBeginTime[0]
          query.data.serviceBeginTimeEndTime = this.serviceBeginTime[1]
        }
        if (!!this.confirmOrderTime && this.confirmOrderTime.length) {
          query.data.confirmOrderTimeStartTime = this.confirmOrderTime[0]
          query.data.confirmOrderTimeEndTime = this.confirmOrderTime[1]
        }
        query.data.status = this.tabList[this.tabIndex].status

        // 从产品管理跳转过来所做的处理
        if (this.isFirstEnter) {
          this.appointmentDates = ''
          query.data.appointmentShootEndTime = ''
          query.data.appointmentShootStartTime = ''
        }
        if (this.serviceIds.length) {
          query.data.serviceIds = this.serviceIds
        }

        const { data } = await getOrderQuery(query)
        this.total = Number(data.total)
        if (data.content.length) {
          this.isOrderData = true
        } else {
          this.isOrderData = false
        }
        this.orderData = data.content

        this.orderData.forEach((el) => {
          const newArr = (el.serviceItems || []).filter((el2) => {
            return Boolean(el2.serviceUserName) && el2.serviceItemStage === 1
          })
          el.arrangedServiceNum = newArr.length || 0
        })

        this.orderLoading = false
      } catch (error) {
        console.log(error)
        this.orderLoading = false
      }
    },
    async handleTab(item, index) {
      await (this.tabIndex = index)
      this.queryData.pageNumber = 1
      Object.assign(this.$data.queryData, this.$options.data().queryData)
      // if (this.tabList[this.tabIndex].status !== 1) this.tabIndex = 0;
      this.appointmentDates = []
      this.successServerTimes = []
      this.appointmentOrderDates = []
      this.initAppointmentDates()

      if (item.name === '待预约') {
        this.getWaitSubscribeList()
        return
      }
      this.getTableList()
    },
    handleSerch() {
      this.isFirstEnter = false
      this.queryData.pageNumber = 1
      if (this.tabList[this.tabIndex].status === 1) {
        this.getWaitSubscribeList()
        return
      }
      this.getData()
    },
    handleReset() {
      this.isFirstEnter = false
      this.serviceIds = []
      Object.assign(this.$data.queryData, this.$options.data().queryData)
      // if (this.tabList[this.tabIndex].status !== 1) this.tabIndex = 0;
      this.appointmentDates = []
      this.successServerTimes = []
      this.appointmentOrderDates = []
      this.serviceBeginTime = []
      this.confirmOrderTime = []
      if (this.tabList[this.tabIndex].status === 1) {
        this.getWaitSubscribeList()
        return
      }
      this.getData()
    },
    handleUnfold() {
      this.isMoreCondition = !this.isMoreCondition
      this.isMoreCondition ? (this.moreConditionName = '收起') : (this.moreConditionName = '展开')
    },
    async handleOperate(type, item) {
      const h = this.$createElement
      console.log(type, item)
      switch (type) {
        case 'gotoOrderDetail':
          this.detailsInfo = item
          this.outerVisible = true
          break
        case 'serachDetail':
          // eslint-disable-next-line no-case-declarations
          const newUrl = this.$router.resolve({
            path: '/orderDetail',
            query: { serviceId: item.serviceId },
          })
          window.open(newUrl.href)
          break
        case 'enterOrder':
          await enterOrder({ data: { serviceId: item.serviceId } })
          this.getTableList()
          this.$msgbox({
            message: h('div', { style: 'text-align:center' }, [
              h('i', {
                class: 'el-icon-success',
                style: 'color:green;font-size:60px; margin:10px 10px 0 0',
              }),
              h('p', { style: 'font-weight:bold; color：#fff' }, '接单成功！'),
              h('p', {}, '请到待服务订单进行查看'),
            ]),
            showCancelButton: false,
            showConfirmButton: false,
            showClose: false,
          })
            .then(() => {})
            .catch(() => {})
          setTimeout(() => {
            const link = document.querySelector('.el-message-box__wrapper')
            link.click()
          }, 3000)
          break
        case 'refuseOrder':
          item.inType = 'list'
          this.currentData = item
          this.isRefuseOrder = true
          break
        case 'enterStop':
          this.$msgbox({
            message: h('div', { style: 'padding:11px' }, [
              h('i', {
                class: 'el-icon-warning',
                style: 'color:#ECA000;font-size:24px; vertical-align: middle;',
              }),
              h(
                'span',
                {
                  style:
                    'font-weight: 500;color: #000000; margin:0 0 0 6px; vertical-align: middle;',
                },
                '确认到店吗',
              ),
              h(
                'p',
                { style: 'font-size:14px; color:#9A9D9E;margin:12px 0 0 30px' },
                '是否确定到店？',
              ),
            ]),
            showCancelButton: true,
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            showClose: false,
          })
            .then(async () => {
              await enterStore({ data: { serviceId: item.serviceId } })
              this.$notify.success({ title: '成功', message: '到店成功', duration: 2000 })
              this.getTableList()
            })
            .catch(() => {})

          break
        // case 'enterServer':
        //   item['inType'] = 'list';
        //   this.currentData = item;
        //   this.isSuccessServer = true;
        //   break;
        case 'addProduct':
          console.log('itemitemitem', item)
          item.inType = 'list'
          this.currentData = item
          this.isAddProduct = true
          break
        case 'uploadImg':
          item.inType = 'list'
          this.currentData = item
          this.isUploadPhoto = true
          break
      }
    },
    handlePagination(val) {
      this.queryData.pageNumber = val
      this.$nextTick(() => {
        this.$refs.elScroll.$refs.wrap.scrollTop = 0
      })
      if (this.tabList[this.tabIndex].status === 1) {
        this.getWaitSubscribeList()
        return
      }
      this.getTableList()
    },
    handleSizeChange(val) {
      this.queryData.pageSize = val
      this.$nextTick(() => {
        this.$refs.elScroll.$refs.wrap.scrollTop = 0
      })
      if (this.tabList[this.tabIndex].status === 1) {
        this.getWaitSubscribeList()
        return
      }
      this.getTableList()
    },
    gotoAddPayOrder(e) {
      this.$router.push({
        name: 'addPayOrder',
        query: {
          customerId: e.customerId,
          serviceId: e.serviceId,
          bizLineId: e.userId + ',' + (e.bizLineId || '1'),
          orderStatus: e.status,
          storeName: e.appointmentStroe,
          storeId: e.appointmentStroeId,
        },
      })
    },
    gotoBeforeShoot(item) {
      const newUrl = this.$router.resolve({
        path: '/orderDetail',
        query: { serviceId: item.serviceId, tabIndex: 8 },
      })
      window.open(newUrl.href)
    },
    remind(row) {
      this.$confirm(`是否确认短信提醒${row.serviceUserName}接单？`, '确认提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          notifyAccept({
            data: row.serviceItemId,
          }).then(() => {
            this.$message({
              type: 'success',
              message: '提醒成功!',
            })
          })
        })
        .catch(() => {})
    },
    async subscribe(item) {
      const reqObj = {
        data: {
          productId: item.orderProduct.productId,
          productType: item.orderProduct.productType,
          productParentId: item.orderProduct.productParentId,
        },
      }

      const res = await getStoreList(reqObj)
      if (res) {
        const storeListAll = res.data.storeList

        if (!storeListAll.find((e) => e.storeId === getStoreId())) {
          this.$message.error('门店已下架该套系产品')
          return
        }
      }

      const origin = window.location.origin
      window.location.assign(
        origin +
          '/index/service/serviceOrder/shedSpaceAppointment?' +
          `id=${item.serviceId}&productId=${item.orderProduct.productId}&productParentId=${
            item.orderProduct.productParentId
          }&productType=${item.orderProduct.productType}&storeId=${getStoreId()}&orderId=${
            item.orderProduct.orderId
          }`,
      )
    },
    async getWaitSubscribeList() {
      const { pageNumber, pageSize } = this.queryData
      const {
        contactPhoneNumber,
        createOrderPhone,
        orderNo,
        serviceNo,
        serviceUserName,
        serviceType,
        bizLineId,
      } = this.queryData.data
      const reqObj = {
        pageNumber,
        pageSize,
        data: {
          contactPhoneNumber,
          createOrderPhone,
          orderNo,
          serviceNo,
          serviceUserName,
          serviceType,
          status: 1,
          bizLineId,
        },
      }
      this.orderLoading = true
      const res = await getWaitSubscribe(reqObj)
      if (res.success) {
        if (this.tabIndex !== 1) {
          this.waiSubscribeTotal = Number(res.data.total)
          return
        }
        this.orderData = res.data.content
        this.total = Number(res.data.total)
        if (res.data.content.length) {
          this.isOrderData = true
        } else {
          this.isOrderData = false
        }
      }
      this.orderLoading = false
    },
    initAppointmentDates() {
      const currentDate = new Date()
      const year = currentDate.getUTCFullYear()
      const month =
        currentDate.getMonth() >= 9 ? currentDate.getMonth() + 1 : `0${currentDate.getMonth() + 1}`
      const day = currentDate.getDate() >= 10 ? currentDate.getDate() : `0${currentDate.getDate()}`
      const start = `${year}-${month}-${day} 00:00:00`
      const end = `${year}-${month}-${day} 23:59:59`

      this.appointmentDates = [start, end]
    },
    async popoverShow(item) {
      const reqObj = {
        data: {
          // orderServiceId: item.orderServiceId,
          serviceId: item.serviceId,
          orderFlowType: item.status > 1 ? null : 1,
          serviceNo: item.serviceNo,
          writeOffType: 1,
        },
      }

      const res = await getOrderServiceOffDetail(reqObj)

      if (res) {
        this.barcode = res.data.barcode
      }

      console.log(this.barcode)
    },
    popoverHide() {
      this.barcode = ''
    },
  },
}
</script>

<style lang="scss" scoped>
.erp-user-id {
  color: #999999;
}
.order-list-container {
  .order-tab {
    padding: 0 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #e0e0e0;
    background-color: #fff;
    .tab-list {
      padding: 11px 0;
      margin-right: 30px;
      font-size: 14px;
      color: #9a9d9e;
      border-bottom: 3px solid #fff;
      &:hover {
        cursor: pointer;
        color: #000;
        border-bottom: 3px solid #2861e2;
      }
    }
    .tab-list-checked {
      color: #000;
      border-bottom: 3px solid #2861e2;
    }
  }
  .search-form-container {
    background: #fff;
    ::v-deep .el-date-editor--datetimerange.el-input__inner {
      width: 380px;
    }
  }
}
.subPlanDialog {
  ::v-deep .el-dialog__footer {
    border-top: 1px solid #ededed;
    padding: 12px 24px;
  }

  .submit-button {
    float: right;
    margin-top: 10px;
  }
}
.order-list {
  padding: 0 20px 0 20px;
  background-color: #fff;
  font-size: 14px;
}
.order-box {
  overflow: hidden;
  max-height: 750px;
}
.pagination {
  padding: 20px 0 24px 0;
  text-align: right;
}

::v-deep .el-dialog__footer {
  border-top: 1px solid #ededed;
  padding: 12px 24px;
}
.colour {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 20px 15px 20px;
  box-sizing: border-box;
  line-height: 1;
  color: #999;
  &__table {
    font-size: 19px;
    margin-right: 10px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: -10px;
      left: -10px;
      right: -10px;
      bottom: -10px;
    }
  }
  &__list {
    font-size: 14px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: -10px;
      left: -10px;
      right: -10px;
      bottom: -10px;
    }
  }
  &--checked {
    background-color: #f5f6f9;
    color: #409eff;
  }
}
</style>

<style>
.tooltip-time-item + .tooltip-time-item {
  margin-top: 4px;
}
</style>
