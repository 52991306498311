<template>
  <el-select-tree
    id="channelSelect"
    ref="tree"
    v-model="channelId"
    :data="channelList"
    :props="{
      label: 'channelTypeName',
      child: 'children',
      value: 'channelTypeId',
    }"
    clearable
    :style="{ width: width }"
    accordion
    lazy
    :size="size"
    :load="onLoadTree"
    placeholder="全部"
    @change="channelTypeChange"
  />
  <!-- <div></div> -->
</template>

<script>
import { getChannelTree, getChannel } from '@/api/misc.js'
import ElSelectTree from 'el-select-tree'
export default {
  components: { ElSelectTree },
  props: {
    value: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '220px',
    },
    size: {
      type: String,
      default: 'small',
    },
  },
  data() {
    return {
      channelList: [],
      channelId: '',
    }
  },
  watch: {
    value: {
      handler(val) {
        this.channelId = val ? val + '-' + 'leaf' : val
      },
    },
  },
  mounted() {
    this.getChannelTypeList()
  },
  methods: {
    queryChannel(list) {
      let data = null
      list.forEach((e) => {
        if (e.id === this.channelId && !data) {
          console.log(e)
          data = e
        }
        if (e.children && e.children.length && !data) {
          const childrenObj = this.queryChannel(e.children)
          if (childrenObj) data = childrenObj
        }
      })
      return data
    },
    async getChannelTypeList() {
      try {
        const res = await getChannelTree({ data: {} })

        this.channelList = res.data
      } catch (e) {
        console.log(e)
      }
    },
    /**
     * 懒加载商机
     */
    onLoadTree(node, resolve) {
      if (Array.isArray(node.data)) {
        return resolve([])
      }
      this.getChannelList(node.data.channelTypeId).then((res) => {
        return resolve([...res, ...node.data.children])
      })
    },
    /**
     * 获取商机叶子节点
     */
    async getChannelList(channelTypeId) {
      const res = await getChannel({
        data: {
          channelTypeId,
        },
      })
      console.log(res.data)
      return res.data.map((e) => {
        e.channelTypeName = e.channelName
        e.channelTypeId = e.id + '-' + 'leaf'
        e.isLeaf = true
        e.children = []
        return e
      })
    },
    channelTypeChange(e) {
      console.log(e)
      this.$emit('input', e.split('-')[0])
    },
  },
}
</script>

<style></style>
